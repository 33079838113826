import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import {DialogLogoutComponent} from '../dialog-logout/dialog-logout.component';
import { StorageService } from '../../services/storage/storage.service';
import { ComunidadModel } from '../../model/comunidad.model';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title = '';
  fileNameDialogRef: MatDialogRef<DialogLogoutComponent>;
  listComunity: ComunidadModel[] = [];
  firstComunity: ComunidadModel;
  selectedComunity: ComunidadModel;
  selectedComunityName = '';
  logo = 'assets/img/icon.png';
   

  constructor(
    public dialog:MatDialog,
    private storageService: StorageService
    ) {    

    }

    onSelected(com: ComunidadModel){
      this.selectedComunity = com;
      this.selectedComunityName = this.selectedComunity.name;
      this.storageService.setSelectedComunidad(com);
    }

  ngOnInit() {
    this.selectedComunity = this.storageService.fetchSelectedComunity();
    if (this.selectedComunity) {
      this.selectedComunityName = this.selectedComunity.name;
    }
    this.fetchComunidades();
  }
  fetchComunidades() {
    this.listComunity = this.storageService.fetchComunidades();
    if ( this.listComunity && this.listComunity.length > 0) {
      this.firstComunity = this.listComunity[0];
    }
  }

   logout(){

this.fileNameDialogRef = this.dialog.open(DialogLogoutComponent, {
      hasBackdrop: true,
      minHeight:150,
      minWidth:300
    });
  }
}


