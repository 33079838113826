import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { ExportToCsv } from 'export-to-csv';

@Injectable()
export class ExporterService {

    constructor(){

    }

    /* EXCEL EXPORT */
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
      }
    
    public  toExportFileName(excelFileName: string): string {
        return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
      }


      /* CSV EXPORT */

      public exportAsCSVFile(data:any[],filename){
        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: filename,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
          };
         
        const csvExporter = new ExportToCsv(options);
         
        csvExporter.generateCsv(data);
      }
    
}
