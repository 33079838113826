import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule , Routes} from '@angular/router';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';


import { AppComponent } from './app.component';
import { NoticiasComponent } from './views/noticias/noticias.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './views/home/home.component';
import { GestionComponent } from './views/gestion/gestion.component';
import { DialogLogoutComponent } from './components/dialog-logout/dialog-logout.component';
import { BookingComponent } from './views/booking/booking.component';

import { HttpService } from './services/http/http.service';
import { AuthService } from './services/auth/auth.service';
import { AlertService } from './services/alert/alert.service';
import { LoginComponent } from './views/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NgProgressModule, NgProgressBrowserXhr } from 'ngx-progressbar';
import { IncidenciasComponent } from './views/incidencias/incidencias.component';
import { ComunidadComponent } from './views/comunidad/comunidad.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
//import { PdfmakeModule } from 'ng-pdf-make';


import {
  MatNativeDateModule,
  MatToolbarModule,
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatTabsModule,
  MatTableModule,
  MatSelectModule,
  MatDialogModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { ServiceModalComponent } from './components/service-modal/service-modal.component';
import { PhoneModalComponent } from './components/phone-modal/phone-modal.component';
import { ExporterService } from './services/exporter/exporter.service';
import { ValidationModalComponent } from './components/validation-modal/validation-modal.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserXhr } from '@angular/http';
import { SessionService } from './services/session/session.service';
import { ModalService } from './services/modal/modal.service';
import { StorageService } from './services/storage/storage.service';
import { AuthGuard } from './common/guards/auth.guard';
import { LoadingSpinnerInterceptor } from './common/interceptor/loading-spinner.interceptor';
import { AuthInterceptor } from './common/interceptor/auth.interceptor';
import { HttpErrorInterceptor } from './common/interceptor/error.interceptor';
import { NoDataComponent } from './components/no-data/no-data.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { IncidenciaComponent } from './views/incidencias/incidencia/incidencia.component';
import { NoticiaComponent } from './views/noticias/noticia/noticia.component';
import { BookComponent } from './views/booking/book/book.component';
import { AddPhoneComponent } from './views/gestion/add-phone/add-phone.component';
import { AddServiceComponent } from './views/gestion/add-service/add-service.component';
import { EditPhoneComponent } from './views/gestion/edit-phone/edit-phone.component';
import { EditServiceComponent } from './views/gestion/edit-service/edit-service.component';
import { UserDetailComponent } from './views/gestion/user-detail/user-detail.component';




const appRoutes : Routes = [
  {path: '',   redirectTo: '/login', pathMatch: 'full'},
  {path:'home',component:HomeComponent, canActivate: [AuthGuard]},
  {path:'login',component:LoginComponent},
  {path:'noticias',component:NoticiasComponent, canActivate: [AuthGuard]},
  {path:'incidencias',component:IncidenciasComponent, canActivate: [AuthGuard]},
  {path:'gestion',component:GestionComponent, canActivate: [AuthGuard]},
  {path:'booking',component:BookingComponent, canActivate: [AuthGuard]},
  {path:'logout',component:LogoutComponent, canActivate: [AuthGuard]}
];

  var config = {
  apiKey: "AIzaSyCdQZ40oiJvcK0HZDsCtW7YB83H3PJpx1E",
  authDomain: "icomunity-4a5ff.firebaseapp.com",
  databaseURL: "https://icomunity-4a5ff.firebaseio.com",
   projectId: "icomunity-4a5ff",
   storageBucket: "icomunity-4a5ff.appspot.com",
   messagingSenderId: "634760725285"
  };
  

@NgModule({
  declarations: [
    AppComponent,
    NoticiasComponent,
    LogoutComponent,
    HomeComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    IncidenciasComponent,
    ComunidadComponent,
    DialogLogoutComponent,
    GestionComponent,
    BookingComponent,
    ServiceModalComponent,
    PhoneModalComponent,
    ValidationModalComponent,
    MessageModalComponent,
    NoDataComponent,
    LoaderComponent,
    ContactSupportComponent,
    IncidenciaComponent,
    NoticiaComponent,
    BookComponent,
    AddPhoneComponent,
    AddServiceComponent,
    EditPhoneComponent,
    EditServiceComponent,
    UserDetailComponent
  ],
  imports: [
    MatSnackBarModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ChartsModule,
 MatToolbarModule,
  MatCardModule,
  MatMenuModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
    MatTabsModule,
  MatTableModule,
    MatSelectModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatTooltipModule,
  BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgProgressModule,
    SweetAlert2Module.forRoot(),
    RouterModule.forRoot(appRoutes,{ useHash: true })
   // firebase.initializeApp(config)
  ],
   entryComponents: [DialogLogoutComponent],

  providers: [
    DatePipe,
    HttpService,
    AuthService,
    AlertService,
    ExporterService,
    SessionService,
    ModalService,
    StorageService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingSpinnerInterceptor,
      multi: true,
    },
  { provide: BrowserXhr, useClass: NgProgressBrowserXhr }],
  bootstrap: [AppComponent]
})
export class AppModule { }
