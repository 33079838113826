import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import {MatSnackBar} from '@angular/material';
import swal from 'sweetalert2';
import { ExporterService } from '../../services/exporter/exporter.service';
import { AlertService } from '../../services/alert/alert.service';
import { ComunityModel } from '../../models/comunity.model';
import { IncidenciModel } from '../../models/incidencia.model';
import { StorageService } from '../../services/storage/storage.service';
import { HttpService } from '../../services/http/http.service';
import { ComunidadModel } from '../../model/comunidad.model';
import { ModalService } from '../../services/modal/modal.service';
import { ServiceModel } from '../../model/service.model';
import { IncidenciaModel } from '../../model/incidencia.model';
const ONE_DAY = 1000 * 60 * 60 * 24 ;
//import * as jsPDF from 'jspdf';
//import 'jspdf-autotable';
//declare let jsPDF;
//import * as html2canvas from 'html2canvas';
declare var jsPDF: any;

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.scss']
})
export class IncidenciasComponent implements OnInit {
  refreshIcon = 'assets/img/refresh.png';

loading:boolean=false;
nb_incidencias:number=0;
minDate = new Date(2018, 0, 1);
maxDate = new Date(2019, 0, 1);
date = new Date();
fechaIn:string;
fechaFin:string;
 services : ServiceModel[];
 model:Model={
    category:"",
    type:"",
    description:""
  };

  types = [
    {value: 'averia', viewValue: 'Averia'},
    {value: 'rotura', viewValue: 'Rotura'},
    {value: 'deterioro', viewValue: 'Deterioro'},
    {value: 'extravio', viewValue: 'Extravio'},
    {value: 'robo', viewValue: 'Robo'},
    {value: 'otro', viewValue: 'Otro'}
   
  ];
  selectedValue:string;
  admin:ComunityModel;
  incidencias=[];
  com_list:ComunityModel[]=[];
  title_incidencia:string="No hay incidencias";
 displayedColumns = ['position', 'fecha', 'vecino','adress', 'categoria','tipo', 'descripcion', 'estado', 'oculto', 'actions'];
 dataSource = new MatTableDataSource(this.incidencias);
complexForm : FormGroup;
com_name:string;
incidencias_pdf:IncidenciModel;
incList:Array<IncidenciModel>=[];
isDownloadable:boolean=false;
list:boolean=true;
nueva:boolean=false;
selectedTab:number=0;
filtroFechaItems = [
  {label:'Todos',value:'todo'},
  {label:'Este mes',value:'this'},
  {label:'El mes pasado',value:'prev'},
  {label:'Buscar con intervales',value:'interval'},
];
selectedFechaOption = 'todo';
setDateinput = {
  in: {
    bool: false,
    value: new Date()
  },
  fin:  {
    bool: false,
    value: new Date()
  }
};
incidenciasCount = 0;
incidenciasDataCached = [];
USER_ACCOUNT_TYPE = 'NORMAL';
selectedComunidad: ComunidadModel;
selectedIncidencia: IncidenciaModel;

  constructor(
    private httpService: HttpService,
    public storageService:StorageService,
    public exporterService:ExporterService,
    public alertService:AlertService,
    private modalService: ModalService,
    private datePipe:DatePipe,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
    ) {

        
     this.complexForm = this.fb.group({
      // We can set default values by passing in the corresponding value or leave blank if we wish to not set the value. For our example, we’ll default the gender to female.
      'category' : [null, Validators.required],
      'type': [null, Validators.required],
      'description': [null, Validators.required]
    })
   }
   ngOnInit() {
    this.init();
  }
  refresh() {
    this.init();
  }
  loadSelectedComunity() {
    this.selectedComunidad = this.storageService.fetchSelectedComunity();
    if (this.selectedComunidad) {
      this.getForfait();
      this.reloadData();
    }
  }
  init() {
    this.loadSelectedComunity();
    this.listenComunityChange();
  }
  listenComunityChange() {
    this.storageService.onSelectedComunidadChange()
    .subscribe( com => {
      console.log('COMUNITY_CHANGE -> INCIDENCIAS -> ', com);
     if ( com ) {
       this.selectedComunidad = com;
       this.reloadData();
     }
    });
  }
  reloadData() {
    this.loadIncidencias();
    this.getServices();
  }

  exitIncidencia(done) {
    this.selectedIncidencia = null;
  }
  selectIncidencia(incidencia) {
    this.selectedIncidencia = incidencia;
  }

  getForfait() {
    this.USER_ACCOUNT_TYPE = this.storageService.getForfait();
  }
  loadIncidencias(){
    this.httpService.doFetchIncidencias(this.selectedComunidad.code)
    .subscribe( (response) => {
      console.log('INCIDENCIAS -> ', response);
      if (response && response.result && response.result.length > 0) {
      //  this.storageService.saveIncidencias(response.result);
        this.incidencias = response.result;
        this.chargeIncidenciasToTable();
      }
    }, error => {
      console.log('FETCH_INCIDENCIAS_ERROR -> ', error);
    });
  }
  Export(event){
    // PRO FEATURE
    if(this.USER_ACCOUNT_TYPE === 'PRO'){
      const type = event.value;
      const filename = 'incidencias';
      const clonedList =  this.storageService.cloneObject(this.incidenciasDataCached);
      const data:any[] = this.mapIncidenciaForExport(clonedList);
      console.log('export type',type);
      switch(type){
        case "excel":
        this.exporterService.exportAsExcelFile(data,filename);
        break;
        case "csv":
        this.exporterService.exportAsCSVFile(data,filename);
        break;
      }
    }else{
      let feature = 'exportar los datos de las incidencias al formato excel';
      this.alertService.alertProFeature(feature);
    }
  }
  mapIncidenciaForExport(data:any[]){
    data.forEach( item => {

      item.comunidad = item.code_comunity;
      item.fecha = this.formatDate(item.created_at);
      item.categoria = item.category;
      item.tipo = item.type;
      item.vecino = item.username;
      item.direccion = item.adress;
      item.descripcion = item.description;

      delete item.adress;
      delete item.code_comunity;
      delete item.description;
      delete item.category;
      delete item.type;
      delete item.username;
      delete item.id;
      delete item.image;
      delete item.state;
      delete item.oculto;
      delete item.created_at;
      delete item.create;
    });
    return data;
  }

   showErrorSB(){
      this.snackBar.open("Debe proporcionar las dos fechas", "ok", {
      duration: 3000,
    });
   }

    showErrorSB2(){
      this.snackBar.open("No hay datos para generar el PDF", "ok", {
      duration: 3000,
    });
   }

   generatePDF(){
       // PRO FEATURE
    if(this.USER_ACCOUNT_TYPE === 'PRO'){
      if(this.fechaFin==undefined || this.fechaIn==undefined){
        this.showErrorSB();
       }else{
       this.loading=true;
       this.httpService.getIncidenciasBetweenDates(this.selectedComunidad.code,this.fechaIn,this.fechaFin)
       .subscribe((response)=>
       {
         let result = response['result'];
         if(!result){
  
         }else{
          this.loading=false;
          this.incidencias_pdf=result;
          this.nb_incidencias = Object.keys(this.incidencias_pdf).length;
          let size = Object.keys(this.incidencias_pdf).length;
          if(size>0)
          this.isDownloadable=true;
         }
       });
       }
    }else{
      let feature = 'generar un pdf con los datos de las incidencias ';
      this.alertService.alertProFeature(feature);
    }
   }


   downloadPDF(){
        // PRO FEATURE
        if(this.USER_ACCOUNT_TYPE === 'PRO'){
          if(!this.isDownloadable){
            this.showErrorSB2();
         }else{
    
            let size = Object.keys(this.incidencias_pdf).length;
           for (let index = 0 ; index < size ; index++) {
                this.incList.push(this.incidencias_pdf[index]);
              }
    
     
          let incidencias = this.incList;
         let rows:Array<any>=[];
         let index = 1;
         for(let item of incidencias){
         
         let row = [index,this.formatSimpleDate(item['created_at']),item['type'],item['category'],item['description']];
          rows.push(row);
          index++;
        
        }
         let today=this.formatSimpleDate(this.date);
         let inicio = this.formatSimpleDate(this.fechaIn);
         let fin = this.formatSimpleDate(this.fechaFin);
    
         let header ="\t \t \t \t \t    ICOMUNITY \n \n"
         +"INCIDENCIAS \n"
         +"Comunidad : "+this.com_name+"\n"
         +"fecha : "+today+" \n"
         +"fecha inicio : "+inicio+"\t"+"fecha fin : "+fin;
    
    
           var columns = ["n°","fecha", "tipo", "categoria","descripcion"];
           const doc = new jsPDF('p', 'pt');
           //var doc = new jsPDF();
    
      
        doc.autoTable(columns, rows,{
        styles: { overflow: 'linebreak' },
        theme: "striped",
       columnStyles: {
        0: {columnWidth:20},
        1: {columnWidth: 80},
        2: {columnWidth: 80},
        3: {columnWidth: 80},
        4: {columnWidth: 250}
    },
        margin: {top: 150},
        addPageContent: function(data) {
          doc.text(header, 40, 30);
        }
           });
           let date = this.datePipe.transform(new Date(), 'yyyy_MM_dd');
    
           doc.save('incidencias_'+date+'.pdf');
           this.incList = null;
           this.isDownloadable=false;
         }
        
        }else{
          let feature = 'generar un pdf con los datos de las incidencias ';
          this.alertService.alertProFeature(feature);
        }
    
   }

    formatDate(date) {
   return  this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

   formatSimpleDate(date) {
   return  this.datePipe.transform(date, 'yyyy-MM-dd');
  }
     addInEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.fechaIn= `${type}: ${event.value}`;
    this.fechaIn=this.formatDate(this.fechaIn);

  }

  addFinEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.fechaFin= `${type}: ${event.value}`;
    this.fechaFin=this.formatDate(this.fechaFin);
  }


 
  getServices(){

    this.httpService.doFetchServices(this.selectedComunidad.code)
    .subscribe((response)=>
    {
      // this.storageService.saveComunidadesServices(response);
      this.services = response;
    })
    

  }


   applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  loadStorageIncidencias(){
    this.incidencias = this.storageService.fetchIncidencias();
    if(this.incidencias.length>0){
     this.chargeIncidenciasToTable();
    }
  }

  chargeIncidenciasToTable() {
    this.modalService.showLoadingModal(3000);
    this.title_incidencia="Incidencias de la comunidad";
    this.incidencias= this.incidencias;
    this.incidenciasCount = this.incidencias.length;
    this.mapIncidencias(this.incidencias);
    this.incidenciasDataCached = this.incidencias;
    this.dataSource = new MatTableDataSource(this.incidencias);
    this.modalService.hideLoadingModal();
  }

  mapIncidencias(list) {
    list.forEach(element => {
      let fecha = new Date(element.created_at);
      let now = new Date();
      let value =  now.getTime() - fecha.getTime();
      element.create =  now.getTime() - fecha.getTime() >  ONE_DAY ? element.created_at : 'hoy';
    });
  }

  changeIncidenciaState(index:number,state:number){
    if(state==1){
    let category = this.incidencias[index]['category'];
    let type = this.incidencias[index]['type'];
    this.incidencias[index]['state']=2;
    this.dataSource = new MatTableDataSource(this.incidencias);
    let code = this.incidencias[index]['code_comunity'];
    let id = this.incidencias[index]['id'];
    this.httpService.requestChangeIncidenciaState(code,id)
    .subscribe((response)=>
    {

    });
    this.httpService.requestSendIncidenciaPush(code)
    .subscribe( response => {

    });
    
    }else{
    this.incidencias[index]['state']=1;
    this.dataSource = new MatTableDataSource(this.incidencias);
    let code = this.incidencias[index]['code_comunity'];
    let id = this.incidencias[index]['id'];
    this.httpService.requestChangeIncidenciaState(code,id)
    .subscribe((response)=>
    {
    });;
    }
  }


  changeIncOcultarState(index:number,state:number){
    if(state==1){
    this.incidencias[index]['oculto']=2;
    this.dataSource = new MatTableDataSource(this.incidencias);
    let code = this.incidencias[index]['code_comunity'];
    let id = this.incidencias[index]['id'];
    this.httpService.requestchangeIncidenciaOcultoState(code,id)
    .subscribe((response)=>
    {
    });
    
    }else{
    this.incidencias[index]['oculto']=1;
    this.dataSource = new MatTableDataSource(this.incidencias);
    let code = this.incidencias[index]['code_comunity'];
    let id = this.incidencias[index]['id'];
    this.httpService.requestchangeIncidenciaOcultoState(code,id)
    .subscribe((response)=>
    {
    });
    }
  }

  addIncidencia(category:string, type:string, description:string){
    this.httpService.addAdminIncidencia(this.selectedComunidad.code,category,type,description).subscribe((response)=>
    {
      console.log('ADD_INCIDENCIA -> ', response);
      this.model.category="";
      this.model.type="";
      this.model.description="";
      this.loadIncidencias();
      this.selectTab(0);
      this.sendIncidenciaPush();
    });
  }

  sendIncidenciaPush(){
    this.httpService.requestSendIncidenciaPush(this.selectedComunidad.code)
    .subscribe(response=>{

    });
  }

  reload() {
 location.reload();
}

selectTab(index: number){
  this.selectedTab = index;
  console.log('selectedTab tab: '+index);
}

info2(){
  if(localStorage.getItem('info2')){

  }else{
    swal(
      'Info !',
      'pasa el ratón sobre las descripciones para ver el texto completo',
      'info'
    );
    localStorage.setItem('info2','true');
      
  }
}

addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  let value = event.value;
  let result = [];
  if(type==='in') {
    this.setDateinput.in.bool = true;
    this.setDateinput.in.value = value;
  }else if(type==='fin'){
    this.setDateinput.fin.bool = true;
    this.setDateinput.fin.value = value;
  }
  if(this.setDateinput.in.bool && this.setDateinput.fin.bool){
    this.incidencias.forEach( item => {
      let fechatime = new Date(item.created_at).getTime();
      let intime = this.setDateinput.in.value.getTime();
      let fintime = this.setDateinput.fin.value.getTime();
      if( (intime < fechatime) && ( fechatime < fintime) ){
        result.push(item);
      }
    });
    this.incidenciasCount = result.length;
    this.incidenciasDataCached = result;
    this.dataSource  = new MatTableDataSource(result);
  }
}

onDateFilterChange(event){
  let month;
  let result;
  let values;
  switch(event.value){
    case 'this':
     month = new Date().getMonth();
     result = this.incidencias;
     values = [];
    result.forEach( (item) => {
      let itemmonth = new Date(item.created_at).getMonth();
      if(itemmonth===month){
        values.push(item);
      }
    });
    this.dataSource  = new MatTableDataSource(values);
    this.clearDatePickerInput();
    break;
    case 'prev':
     month = new Date().getMonth() - 1;
     result = this.incidencias;
     values = [];
    result.forEach( (item) => {
      let itemmonth = new Date(item.created_at).getMonth();
      if(itemmonth===month){
        values.push(item);
      }
    });
    this.dataSource  = new MatTableDataSource(values);
    this.clearDatePickerInput();
    break;
    case 'interval':
    values = [];
    this.dataSource  = new MatTableDataSource(values);
    break;
    case 'todo':
    values = this.incidencias;
    this.dataSource  = new MatTableDataSource(this.incidencias);
    this.clearDatePickerInput();
    break;
  }
  this.incidenciasCount = values.length;
  this.incidenciasDataCached = values;
}

clearDatePickerInput(){
  this.setDateinput = {
    in: {
      bool: false,
      value: new Date()
    },
    fin:  {
      bool: false,
      value: new Date()
    }
  };
}



}


interface Model{
  category:string,
  type:string,
  description:string
}
interface Service{
  type:string,
  name:string
}
