import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { HttpService } from '../../services/http/http.service';
import { ComunityModel } from '../../models/comunity.model';
import { IncidenciModel } from '../../models/incidencia.model';
import { PadelModel } from '../../models/padel.model';
import { LocalModel } from '../../models/local.model';
import { VecinoModel } from '../../models/vecino.model';
import { PiscinaModel } from '../../models/piscina.mode';
import { SolariumModel } from '../../models/solarium.model';
import { StorageService } from '../../services/storage/storage.service';
import { ComunidadModel } from '../../model/comunidad.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  USER_ACCOUNT_TYPE = 'NORMAL';
  showMenu:boolean=true;
  listComunity:ComunityModel[];
  firstComunity: ComunityModel;
  public barChartLabels:string[] = ['Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Enero', 'Febrero', 'Marzo'];
  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;
 
  public barChartData:any[] = [
    {data: [65, 59, 80, 81, 56, 55, 40], label: 'Incidencias '},
    {data: [50, 20, 30, 50, 60, 70, 80], label: 'Informaciones '},
    {data: [28, 48, 40, 19, 86, 27, 90], label: 'Documentos '}
  ];

  users: VecinoModel[];
  incidencias:IncidenciModel[];
  reservasLocal: LocalModel[];
  reservasPadel: PadelModel[];
  reservasPiscina: PiscinaModel[];
  reservasSolarium: SolariumModel[];
  totalIncidenciasMes = 0;
  totalIncidenciasMesSolved = 0;
  totalIncidenciasMesPending = 0;
  totalIncidenciasMesOcultas = 0;
  totalReservasLocal = 0;
  totalReservasLocalMes = 0;
  totalPagosLocalReservasMes = 0;
  totalPagosLocalReservas = 0;
  totalVecinos = 0;
  totalVecinosActive = 0;
  totalVecinosLocked = 0;
  totalVecinosDeleted = 0;
  //PISCINA/SOLARIUM
  totalReservasPiscina = 0;
  totalReservasPiscinaCanceladas = 0;
  totalReservasPiscinaMes = 0;
  totalReservasPiscinaMesCanceladas = 0;
  
  totalReservasSolarium = 0;
  totalReservasSolariumCanceladas = 0;
  totalReservasSolariumMes = 0;
  totalReservasSolariumMesCanceladas = 0;

  public incidenciasPieChartLabels:string[] = [' Incidencias pendientes', 'Incidencias resueltas'];
  public incidenciasPieChartData:number[] = [this.totalIncidenciasMesPending, this.totalIncidenciasMesSolved];
  public reservasLocalPieChartLabels:string[] = ['Total reservas de local', 'Reservas de local del mes'];
  public reservasLocalPieChartData:number[] = [this.totalReservasLocal, this.totalReservasLocalMes];
  public pieChartType:string = 'pie';
   public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  selectedComunidad: ComunidadModel;

  constructor(
    private httpService: HttpService,
    private storageService: StorageService) {
   }

  ngOnInit() {
    this.init();
  }
  getForfait() {
    this.USER_ACCOUNT_TYPE = this.storageService.getForfait();
  }
  init() {
    this.loadSelectedComunity();
    this.loadData();
    this.listenSelectedComunidadChange();
  }
  loadSelectedComunity() {
    this.selectedComunidad = this.storageService.fetchSelectedComunity();
    if (this.selectedComunidad) {
      this.loadUsers();
      this.loadIncidencias();
      this.loadReservas();
    }
  }
  fetchComunidades() {
    this.listComunity = this.storageService.fetchComunidades();
    if ( this.listComunity && this.listComunity.length > 0) {
      this.firstComunity = this.listComunity[0];
    }
  }
  listenSelectedComunidadChange() {
    this.storageService.onSelectedComunidadChange()
    .subscribe( com => {
    console.log('COMUNITY_CHANGE -> HOME -> ', com);
     if ( com ) {
       this.selectedComunidad = com;
       this.loadUsers();
       this.loadIncidencias();
       this.loadReservas();
     }
    });
  }
  loadData(){
    this.fetchComunidades();
  }
  loadUsers() {
    this.httpService.doFetchUsers(this.selectedComunidad.code)
    .subscribe( (response) => {
      // this.storageService.saveUsers(response.result);
      this.users = response.result;
      if ( this.users && this.users.length > 0) {
        this.calculateUsers();
      }
    }, error => {
      console.log('ERROR FETCH USERS -> ', error);
    });
  }

  loadIncidencias(){
    this.httpService.doFetchIncidencias(this.selectedComunidad.code)
    .subscribe( (response) => {
      // this.storageService.saveIncidencias(response.result);
      this.incidencias = response.result;
      if (this.incidencias && this.incidencias.length > 0) {
        this.calculateIncidencias();
      }
    }, error => {
      console.log('FETCH_INCIDENCIAS_ERROR -> ', error);
    });
  }
  loadReservas(){
    this.httpService.doFetchReservasLocal(this.selectedComunidad.code)
    .subscribe( (responseLocal) => {
      this.totalReservasLocal = responseLocal.length;
      // this.storageService.saveLocalBooking(responseLocal);
      if(responseLocal.length>0){
        this.reservasLocal = responseLocal;
        this.calculateReservasLocal();
      }
    });

    this.httpService.doFetchReservasPadel(this.selectedComunidad.code)
    .subscribe( (responsePadel) => {
      this.totalReservasLocal = responsePadel.length;
      // this.storageService.savePadelBooking(responsePadel);
    });
    this.httpService.doFetchReservasPiscina(this.selectedComunidad.code)
    .subscribe( (responsePiscina) => {
      // this.storageService.savePiscinaBooking(responsePiscina);
      if(responsePiscina.length > 0) {
        this.reservasPiscina = responsePiscina;
        this.calculateReservasPiscina();
      }
    });

    this.httpService.doFetchReservasSolarium(this.selectedComunidad.code)
    .subscribe( (responseSolarium) => {
      // this.storageService.saveSolariumBooking(responseSolarium);
      if(responseSolarium.length > 0) {
        this.reservasSolarium = responseSolarium;
        this.calculateReservasSolarium();
      }
    });
  
  }
  calculateUsers(){
    let locked = 0;
    let deleted = 0;
  
    this.users.forEach(item=> {
      if(item.state === 1)
      locked += 1;
      if(item.state === 2)
      deleted += 1;
    });
    this.totalVecinos = this.users.length;
    this.totalVecinosActive = this.users.length - locked - deleted;
    this.totalVecinosLocked = locked ;
    this.totalVecinosDeleted = deleted;
  }
  calculateIncidencias(){
    // INCIDENCIAS DEL MES
     let month = new Date().getMonth();
     let result = this.incidencias;
     let totalMes = 0;
     let totalSolved = 0;
     result.forEach( (item) => {
      let itemmonth = new Date(item.created_at).getMonth();
      if(itemmonth===month){
        totalMes += 1;
        if(item.state!=1)
        totalSolved += 1;
      }
    });
    this.totalIncidenciasMes = totalMes;
    this.totalIncidenciasMesSolved = totalSolved;
    this.totalIncidenciasMesPending = totalMes - totalSolved;
    this.incidenciasPieChartData = [this.totalIncidenciasMesPending, this.totalIncidenciasMesSolved];
    
  }

  calculateReservasLocal(){
    let month = new Date().getMonth();
    let totalMes = 0;
    let totalPagosMes = 0;
    let totalPagos =  0;
        this.reservasLocal.forEach( (item) => {
          const price = item.hours.length;
          let itemmonth = new Date(item.day).getMonth();
          totalPagos += Number(price);
          if(itemmonth===month){
            totalMes += 1;
            totalPagosMes += Number(price);
          }
        });
        this.totalReservasLocal = this.reservasLocal.length;
        this.totalReservasLocalMes = totalMes;
        this.totalPagosLocalReservasMes = totalPagosMes;
        this.totalPagosLocalReservas = totalPagos;
        this.reservasLocalPieChartData = [this.totalReservasLocal, this.totalReservasLocalMes];
  }

  calculateReservasPiscina() {
    this.totalReservasPiscina = 0;
    this.totalReservasPiscinaCanceladas = 0;
    this.totalReservasPiscinaMes = 0;
    this.totalReservasPiscinaMesCanceladas = 0;

    this.totalReservasPiscina = this.reservasPiscina.length >= 0 ? this.reservasPiscina.length : 0;
    let month = new Date().getMonth();
    this.reservasPiscina.forEach( (item) => {
      let itemmonth = new Date(item.day).getMonth();
      if (!item.active) {
        this.totalReservasPiscinaCanceladas ++;
      }
      if(itemmonth===month){
        this.totalReservasPiscinaMes ++;
        if ( !item.active )
        this.totalReservasPiscinaMesCanceladas ++;
      }
    });
  }

  calculateReservasSolarium() {
    this.totalReservasSolarium = 0;
    this.totalReservasSolariumCanceladas = 0;
    this.totalReservasSolariumMes = 0;
    this.totalReservasSolariumMesCanceladas = 0;

    this.totalReservasSolarium = this.reservasSolarium.length >= 0 ? this.reservasSolarium.length : 0;
    let month = new Date().getMonth();
    this.reservasSolarium.forEach( (item) => {
      let itemmonth = new Date(item.day).getMonth();
      if (!item.active) {
        this.totalReservasSolariumCanceladas ++;
      }
      if(itemmonth===month){
        this.totalReservasSolariumMes ++;
        if ( !item.active )
        this.totalReservasSolariumMesCanceladas ++;
      }
    });
  }
  // events
  public chartClicked(e:any):void {
    console.log(e);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  }

 public getLastMonths (){
            var date = new Date();
            var months = [],
                monthNames = [ "Dic", "Nov", "Oct", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
            for(var i = 0; i < 6; i++) {
                months.push(monthNames[date.getMonth()]);
            }    
            this.barChartLabels = months;
        }
info9(){
  if(localStorage.getItem('info9')){

  }else{
    swal(
      '¡ NOVEDADES !',
      'Ahora tienes los datos de reservas de piscina/solarium en la pagina RESERVAS y un resumen en la pagina de INICIO ! ',
      'info'
    );
    localStorage.setItem('info9','true');
      
  }
}
      }
