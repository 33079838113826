export const environment = {
  production: false,
  apiUrl: 'https://backbone.berekstan.com:4432',
  apiUrlCore: 'https://backbone.berekstan.com:4433/core',
  apiUrlZeus: 'https://backbone.berekstan.com:4434',
  apiUrlPush: 'https://backbone.berekstan.com:4431/push',
  apiUrlAccount: 'https://backbone.berekstan.com:4472/core',
  apiUrlBookingLocal: 'https://backbone.berekstan.com:4439/booking',
  apiUrlBookingPadel: 'https://backbone.berekstan.com:4430/booking',
  apiUrlBookingPiscina: 'https://backbone.berekstan.com:4490/booking',
  apiUrlBookingSolarium: 'https://backbone.berekstan.com:4492/booking',
  assetsUrl: 'http://localhost:3131/file-uploader-folder',
};