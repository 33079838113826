import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {HttpService} from '../../services/http/http.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import swal from 'sweetalert2';
import {SelectionModel} from '@angular/cdk/collections';
import { StorageService } from '../../services/storage/storage.service';
import { ServiceModel } from '../../models/service.model';
import { PhoneModel } from '../../models/phone.model';
import { ModalService } from '../../services/modal/modal.service';
import { AlertService } from '../../services/alert/alert.service';
import { ComunidadModel } from '../../model/comunidad.model';
import { UserModel } from '../../model/vecino.model';

@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.component.html',
  styleUrls: ['./gestion.component.scss']
})
export class GestionComponent implements OnInit {
  refreshIcon = 'assets/img/refresh.png';
form: FormGroup;
loading: boolean = false;
@ViewChild('fileInput', {read: null, static: false}) fileInput: ElementRef;
@ViewChild('stateSelect', {read: null, static: false}) stateSelect;
@ViewChild('actionSelect', {read: null, static: false}) actionSelect;

selectedComunidad: ComunidadModel;
users=[];
users_clean=[];
users_all=[];
users_locked=[];
users_deleted=[];
columns_select= ['select','position', 'name', 'email', 'phone','adress', 'actions'];
service_columns_select= ['position','state', 'type', 'name', 'phone','actions'];
phone_columns_select = ['position','state', 'name', 'phone','actions'];
columns= ['position', 'name', 'email', 'phone','adress'];
displayedColumns = [];
serviceDisplayedColumns = [];
phoneDisplayedColumns = [];
dataSource = new MatTableDataSource(this.users_clean);
serviceDataSource = new MatTableDataSource([]);
phoneDataSource = new MatTableDataSource([]);
selection = new SelectionModel<Element>(true, []);
selected_users =[];
actions=[];
actions_all=["bloquear","suprimir"];
actions_locked=["desbloquear","suprimir"];
states=["todos","bloqueados","suprimidos"];
bool_state:number=0;
bool_action:number=0;
selected_action:string;
selected_state:string="todos";
//Messages
message_state:string="Total vecinos";
//disabled
disabled_state:boolean[]=[true,false,false];
table_selected:number=0;
count = 0;
serviceItem : ServiceModel ;
phoneItem : PhoneModel;
//ICONS
addIcon = 'assets/icon/add_white.png';

//SERVICES 
serviceList:ServiceModel[]=[];
// PHONES
phoneList:PhoneModel[]=[];

// SPECIAL FEATURES FOR PAYMENT RELEASE
payment = false;
selectedUser: UserModel;
USER_ACCOUNT_TYPE = 'NORMAL';

  constructor(private httpService: HttpService,public storageService: StorageService,public alertService:AlertService,
     private fb: FormBuilder, public modalService: ModalService) {
     this.createForm();
     this.modalService.hideServiceModal();
     this.modalService.hidePhoneModal();
   }

  ngOnInit() {
    this.displayedColumns = this.columns_select;
    this.serviceDisplayedColumns = this.service_columns_select;
    this.phoneDisplayedColumns = this.phone_columns_select;
    this.actions=this.actions_all;
    this.loadSelectedComunity();
    this.listenComunityChange();
  }
  refresh() {
    this.loadSelectedComunity();
  }
  reloadData() {
    this.getUserList();
    this.getServices();
    this.getPhones();
    this.getForfait();
  }
  loadSelectedComunity() {
    this.selectedComunidad = this.storageService.fetchSelectedComunity();
    console.log('SELECTED_COM -> ', this.selectedComunidad);
    if (this.selectedComunidad) {
      this.reloadData();
    }
  }
  listenComunityChange() {
    this.storageService.onSelectedComunidadChange()
    .subscribe( com => {
    console.log('COMUNITY_CHANGE -> GESTION -> ', com);
     if ( com ) {
       this.selectedComunidad = com;
       this.reloadData();
     }
    });
  }
  getForfait() {
    this.USER_ACCOUNT_TYPE = this.storageService.getForfait();
  }
  validationCallback(response){
    console.log('validation -> ',response);
  }

  phoneSaved(phone: PhoneModel){
    swal({
      title: 'Confirmación',
      text: "¿ Está seguro que quiere actualizar este teléfono ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.httpService.updatePhone(phone)
        .subscribe( response => {
          this.getPhones();
        }, error => {
          console.log('error ',error);
        })
      }
    });
  }

  serviceSaved(service: ServiceModel){
    swal({
      title: 'Confirmación',
      text: "¿ Está seguro que quiere actualizar este servicio ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.httpService.updateService(service)
        .subscribe( response => {
          this.getServices();
        }, error => {
          console.log('error ',error);
        })
      }
    });
  }

  editService(service:ServiceModel){
    this.serviceItem = service;
    this.modalService.showServiceModal();
  }

  selectUser(user: UserModel) {
    console.log('SELECTED USER -> ', user);
    this.selectedUser = user;
  }
deleteService(service:ServiceModel){
 swal({
      title: 'Confirmación',
      text: "¿ Está seguro que quiere suprimir este servicio ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.httpService.deleteService(service)
        .subscribe( res => {
          this.getServices();
        })
      }
    })
  }

  editPhone(phone:PhoneModel){
    console.log('edit phone',phone);
    this.phoneItem = phone;
    this.modalService.showPhoneModal();
  }

  deletePhone(phone: PhoneModel){
    swal({
      title: 'Confirmación',
      text: "¿ Está seguro que quiere suprimir este teléfono ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.httpService.deletePhone(phone)
        .subscribe( res => {
          this.getPhones();
        })
      }
    });
  }
  addService(){
    if(this.USER_ACCOUNT_TYPE === 'PRO'){
 
    }else{
      let feature = ' añadir un nuevo servicio ';
      this.alertService.alertProFeature(feature);
    }
  }
  addPhone(){
    if(this.USER_ACCOUNT_TYPE === 'PRO'){
 
    }else{
      let feature = ' añadir nuevos telefonos  ';
      this.alertService.alertProFeature(feature);
    }
  }
  getServices(){
    this.httpService.getServices(this.selectedComunidad.code).
    subscribe( result => {
      this.serviceList = result;
      this.serviceDataSource = new MatTableDataSource(this.serviceList);
    }, error => {
      console.log('error fetching services ', error);
    })
  }
  getPhones(){
    this.httpService.getPhones(this.selectedComunidad.code).
    subscribe( result => {
      console.log('PHONES',result);
      this.phoneList = result;
      this.phoneDataSource = new MatTableDataSource(this.phoneList);
    }, error => {
      console.log('error fetching phones ', error);
    })
  }

  refreshUserList(){
    this.httpService.doFetchUsers(this.selectedComunidad.code).subscribe((response)=>{
     console.log('refresh',response);
          let result = response['result'];
           let size = Object.keys(result).length;
           if(size>0){
           this.users = result;
           this.count = this.users.length;
           this.users_all = result;
           this.filterCleanUser();
           this.filterLockedUser();
           this.filterDeletedUser();
           this.loadTable();
           }
  });
}
  getUserList(){
    const result = this.storageService.fetchUsers();
        if(result){
          let size = Object.keys(result).length;
          if(size>0){
          this.users = result;
          this.count = this.users.length;
          this.users_all = result;
          this.filterCleanUser();
          this.filterLockedUser();
          this.filterDeletedUser();
          this.loadTable();
          }
        }
        else{
        this.httpService.doFetchUsers(this.selectedComunidad.code).subscribe((response)=>{
              let result = response['result'];
              if(!result){
     
              }else{
               let size = Object.keys(result).length;
               if(size>0){
               this.users = result;
               this.count = this.users.length;
               this.users_all = result;
               this.chargeUsersinTable();
               }else{
                 //show empty user list message
               }
     
              }
             
         },error => {
         });  
        }
      }
chargeUsersinTable() {
  this.filterCleanUser();
  this.filterLockedUser();
  this.filterDeletedUser();
  this.loadTable();
}
 applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


  
  createForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      email: ['', Validators.required],
      file: null
    });
  }

  onFileChange(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.get('file').setValue({
          filename: file.name,
          filetype: file.type,
          value: reader.result
        })
      };
    }
  }

  onSubmit() {
    const formModel = this.form.value;
    this.loading = true;
    console.log('title : '+ formModel.title+'admin email : '+ this.selectedComunidad.admin_email+'dest : '+ formModel.email+'image : '+ formModel.file.value);
    this.httpService.sendDoc(formModel.title,this.selectedComunidad.admin_email,formModel.file.value,formModel.email);
    // In a real-world app you'd have a http request / service call here like
    // this.http.post('apiUrl', formModel)
    setTimeout(() => {
      console.log(formModel);
      alert('done!');
      this.loading = false;
    }, 1000);
  }

  clearFile() {
    this.form.get('avatar').setValue(null);
    this.fileInput.nativeElement.value = '';
  }
  stateEvent(state:string){
    console.log("event :"+state);

    //this.stateSelect.close();
    this.selected_state=state;
  switch(state){
    case "todos":
    this.updateState(0);
    break;

    case "bloqueados":
    this.updateState(1);
    break;

    case "suprimidos":
    this.updateState(2);
    break;

  }
    

  }

  actionEvent(action:string){
    if(this.USER_ACCOUNT_TYPE === 'PRO'){
      let size = this.selection.selected.length;
      if(size == 0){
        this.onNothingSelected();
      }else if (size==1){
        let text = "el usuario "+this.selection['_selected'][0].name;
        this.confirmAction(action,text);
      }else if(size > 1){
        let text= "los usuarios : ";
        for(let item of this.selection['_selected']){
          if(item.name==this.selection['_selected'][0].name)
          text +=item.name ;
          else
          text +=' , '+ item.name;
        }
        this.confirmAction(action,text);
      }
      this.selected_action="";
    }else{
      let feature = ' cambiar el estado de tus usuarios ';
      this.alertService.alertProFeature(feature);
    }
    
  }

  onNothingSelected(){
    swal(
    'Opsss....',
    'Tiene que seleccionar al menos un usuario para realizar esta accion',
    'error')
  }

  onSuccess(){
    swal(
      'Exito !',
      'Operacion realizada con exito',
      'success'
    );
      
  }
  onFail(){
    swal(
    'Lo siento !',
    'Algo raro ocurro haciendo la reservacion, por favor compruebe otra vez',
    'error')
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  confirmAction(action,text){
    swal(
      {
      title: 'Estas seguro?',
      text: "Quieres "+action+" "+text+" ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, confirmar'
    }).then((result) => {
      if (result.value) {
        let list=[];
        for(let item of this.selection['_selected']){
            list.push(item.id);
            console.log("id : "+item.id);
        }
        let json_list= JSON.stringify(list);
        console.log("json list : "+json_list);
        this.updateUserState(json_list,action);
       
      }
    });
  }
  
  updateState(value:number){

    switch(value){
      case 0:
     // this.states=this.states_all;
     this.disabled_state[0]=true;
     this.disabled_state[1]=false;
     this.disabled_state[2]=false;
      this.actions=this.actions_all;
      this.displayedColumns=this.columns_select;
      this.users=this.users_clean;
      this.dataSource = new MatTableDataSource(this.users); 
      this.message_state="todos los vecinos";  
      this.selected_state="todos";
      this.count = this.users.length;
      break;
      case 1:
      //this.states=this.states_locked;
      this.disabled_state[0]=false;
      this.disabled_state[1]=true;
      this.disabled_state[2]=false;
      this.actions=this.actions_locked;
      this.displayedColumns=this.columns_select;
      this.users=this.users_locked;
      this.dataSource = new MatTableDataSource(this.users);  
      this.selected_state="bloqueados";
      this.message_state="Vecinos bloqueados"; 
      this.count = this.users.length;
      break;
      case 2:
     // this.states=this.states_deleted;
     this.disabled_state[0]=false;
      this.disabled_state[1]=false;
      this.disabled_state[2]=true;
      this.actions=[];
      this.displayedColumns=this.columns;
      this.users=this.users_deleted;
      this.dataSource = new MatTableDataSource(this.users);   
      this.selected_state="suprimidos";
      this.message_state="Vecinos suprimidos";
      this.count = this.users.length;
      break;
    }

  }

  filterLockedUser(){
    this.users_locked = this.users.filter(
      user => user.state == 1
    );
  }

  filterCleanUser(){
    this.users_clean = this.users.filter(
      user => user.state == 0
    );
  }

  filterDeletedUser(){
    this.users_deleted = this.users.filter(
      user => user.state == 2
    );
  }

  updateUserState(id_list,state){
    this.httpService.requestUpdateUserState(this.selectedComunidad.code,id_list,state)
    .subscribe(response=>
   {
     let result = response['result'];
     console.log('response update user state:'+result);
     if(!result){

     }else{
       this.getUserList();
       this.selected_action='';
       this.onSuccess();
       this.selection=new SelectionModel<Element>(true, []);
     }
   }, error=>{
     console.log(error)
   });
  }

  loadTable(){
    switch(this.selected_state){
      case "todos":
      this.dataSource = new MatTableDataSource(this.users_clean);  
      break;
      case "bloqueados":
      this.dataSource = new MatTableDataSource(this.users_locked);  
      break;
      case "suprimidos":
      this.dataSource = new MatTableDataSource(this.users_deleted);  
      break;
    }


  }
}
