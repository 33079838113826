import { Component, OnInit } from '@angular/core';
import { ComunidadModel } from '../../model/comunidad.model';
import { HttpService } from '../../services/http/http.service';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent implements OnInit {
  opened = false;
  supportIcon = 'assets/img/support.png';
  closeIcon = 'assets/img/close.png';
  message;
  sent = false;
  done = false;
  listComunity: ComunidadModel[];
  firstComunity: ComunidadModel;
  constructor(
    private httpService: HttpService,
    private storageService: StorageService,
  ) { }

  ngOnInit() {
    this.fetchComunidades();
  }
  fetchComunidades() {
    this.listComunity = this.storageService.fetchComunidades();
    if ( this.listComunity && this.listComunity.length > 0) {
      this.firstComunity = this.listComunity[0];
    }
  }
  open() {
    this.opened = true;
  }
  close () {
    this.opened = false;
    this.message = null;
    this.done = false;
    this.sent = false;
  }

  sendMessageToSupport() {
    if ( this.firstComunity && this.firstComunity.admin_name) {
      this.httpService.sendSolicitudSoporte(this.firstComunity.admin_name, this.firstComunity.admin_email, this.message)
      .subscribe( response => {
        console.log('SOPORTE RESPONSE -> ', response);
        this.done = true;
        this.sent = true;
        this.message = null;
        setTimeout(() => {
          this.close();
        }, 5000);
      }, error => {
        console.log('SOPORTE ERROR -> ', error);
        this.done = true;
        this.sent = false;
      });
    }
  }

}
