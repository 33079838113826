import { Injectable } from '@angular/core';
import { PREFIX_STORAGE, KEY_COMUNIDADES,
     KEY_INCIDENCIAS, KEY_NOTICIAS, KEY_USERS,
     KEY_LOCAL_BOOKING, KEY_PADEL_BOOKING, KEY_ADMIN_INFOS, KEY_COM_PHONES, KEY_COM_SERVICES, KEY_PISCINA_BOOKING, KEY_SOLARIUM_BOOKING, KEY_FORFAIT, KEY_SELECTED_COMUNITY } from '../service.constants';
import { ComunidadModel } from '../../model/comunidad.model';
import { IncidenciaModel } from '../../model/incidencia.model';
import { NoticiaModel } from '../../model/noticia.model';
import { UserModel } from '../../model/vecino.model';
import { LocalBookModel } from '../../model/local-book.model';
import { PadelBookModel } from '../../model/padel-book.model';
import { AdminInfosModel } from '../../model/admin-infos.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { PhoneModel } from '../../model/phone.model';
import { ServiceModel } from '../../model/service.model';
import { PiscinaModel } from '../../models/piscina.mode';
import { SolariumModel } from '../../models/solarium.model';
import * as _ from 'lodash';

@Injectable()
  export class StorageService {
    picture = '';
    showModalPicture = false;
    selectedComunidadSubject = new BehaviorSubject<ComunidadModel>(null);
    constructor(
        
    ) {}

    cloneObject(object) {
        return  _.cloneDeep(object);
      }
      setForfait(forfait: string) {
        this.saveData(KEY_FORFAIT, forfait);
      }
    getForfait() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_FORFAIT}`));
    }
    saveComunidadesPhones(data: PhoneModel[]) {
        this.saveData(KEY_COM_PHONES, data);
    }
    fetchComunidadesPhones() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_COM_PHONES}`));
    }
    saveComunidadesServices(data: ServiceModel[]) {
        this.saveData(KEY_COM_SERVICES, data);
    }
    fetchComunidadesServices() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_COM_SERVICES}`));
    }
    setSelectedComunidad(com: ComunidadModel) {
        this.saveData(KEY_SELECTED_COMUNITY, com);
        this.selectedComunidadSubject.next(com);
    }
    onSelectedComunidadChange(): Observable<any> {
    return this.selectedComunidadSubject;
    }
    fetchSelectedComunity() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_SELECTED_COMUNITY}`));
    }
    saveAdminInfos(data: AdminInfosModel) {
        this.saveData(KEY_ADMIN_INFOS, data);
    }
    fetchAdminInfos() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_ADMIN_INFOS}`));
    }
    saveNoticias(data: NoticiaModel[]) {
        this.saveData(KEY_NOTICIAS, data);
    }
    fetchNoticias() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_NOTICIAS}`));
    }
    saveUsers(data: UserModel[]) {
        this.saveData(KEY_USERS, data);
    }
    fetchUsers() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_USERS}`));
    }
    saveLocalBooking(data: LocalBookModel[]) {
        this.saveData(KEY_LOCAL_BOOKING, data);
    }
    fetchLocalBooking() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_LOCAL_BOOKING}`));
    }
    savePadelBooking(data: PadelBookModel[]) {
        this.saveData(KEY_PADEL_BOOKING, data);
    }
    fetchPadelBooking() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_PADEL_BOOKING}`));
    }
    savePiscinaBooking(data: PiscinaModel[]) {
        this.saveData(KEY_PISCINA_BOOKING, data);
    }
    fetchPiscinaBooking() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_PISCINA_BOOKING}`));
    }
    saveSolariumBooking(data: SolariumModel[]) {
        this.saveData(KEY_SOLARIUM_BOOKING, data);
    }
    fetchSolariumBooking() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_SOLARIUM_BOOKING}`));
    }
    saveIncidencias(data: IncidenciaModel[]) {
        this.saveData(KEY_INCIDENCIAS, data);
    }
    fetchIncidencias() {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_INCIDENCIAS}`));
    }
    saveComunidades(data: ComunidadModel[]) {
        this.saveData(KEY_COMUNIDADES, data);
    }
     fetchComunidades() {
        return  JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${KEY_COMUNIDADES}`));
    }
    showPictureModal(pic: string) {
        this.picture = pic;
        this.showModalPicture = true;
    }
    hidePictureModal() {
        this.showModalPicture = false;
    }
    saveData(prop: string, data: any) {
        if ( data ) {
            localStorage.setItem(`${PREFIX_STORAGE}${prop}`, JSON.stringify(data));
        }
    }

    getData(prop: string) {
        return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}${prop}`));
    }

    clearData(prop?: string) {
        if ( prop ) {
            localStorage.removeItem(`${PREFIX_STORAGE}${prop}`);
        } else {
            localStorage.clear();
        }
    }

    clearAll() {
        this.selectedComunidadSubject.next(null);
        localStorage.clear();
    }
  }
