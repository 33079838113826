import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../../services/alert/alert.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(public router: Router, private alertService: AlertService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error) => {
                if ( error.name === 'HttpErrorResponse' && error.status !== 404) {
                    this.alertService.error('Hubo un error de conexión. Por favor vuelva a intentarlo');
                }
            return this.throwError(error);
        })
        );
    }

    throwError(err: any): import("rxjs/Observable").ObservableInput<any> {
        throw new Error('Error de conexión.');
      }
}
