import { Injectable } from '@angular/core';
import { ServiceModel } from '../../models/service.model';
import { PhoneModel } from '../../models/phone.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SessionService } from '../session/session.service';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AdminUserModel } from '../../model/admin-user.model';
import { ComunidadExtras } from '../../model/com-extras.model';
import { ComunidadModel } from '../../model/comunidad.model';
import { PortalModel } from '../../model/portal.model';
import {  Observable } from 'rxjs';
const headers = new HttpHeaders({'Content-type': 'application/json; charset=utf-8'});



@Injectable()
export class HttpService {
  getNewsURL:string="https://backbone.berekstan.com:4433/core/get/news";
  addNewsURL:string="https://backbone.berekstan.com:4433/core/add/news";
  deleteNewsURL:string="https://backbone.berekstan.com:4433/core/delete/news";

  //INCIDENCIAS
  getIncidenciasURL:string="https://backbone.berekstan.com:4433/core/admin/get/incidencias";
  changeIncidenciaStateURL:string="https://backbone.berekstan.com:4433/core/update/incidenciaState";
  changeIncOcultoStateURL:string="https://backbone.berekstan.com:4433/core/update/incidenciaOcultoState";
  addAdminIncidenciaURL:string="https://backbone.berekstan.com:4433/core/admin/add/incidencia";

  //USERS 
   getUserListURL:string="https://backbone.berekstan.com:4472/core/get/users";
   updateUserStateURL:string="https://backbone.berekstan.com:4433/core/update/userState";

//SERVICES
  getServicesURL:string="https://backbone.berekstan.com:4433/core/get/services";
  updateServiceURL:string="https://backbone.berekstan.com:4433/core/update/service";
  deleteServiceURL:string="https://backbone.berekstan.com:4433/core/delete/service";
//PHONES
getPhonesURL:string="https://backbone.berekstan.com:4433/core/get/phones";
updatePhoneURL:string="https://backbone.berekstan.com:4433/core/update/phone";
deletePhoneURL:string="https://backbone.berekstan.com:4433/core/delete/phone";
//DOCUMENTS
sendDocURL:string="https://backbone.berekstan.com:4433/core/add/documento";

//PUSH
sendNoticiaPushURL:string="https://backbone.berekstan.com:4431/push/news";
sendIncidenciaPushURL:string="https://backbone.berekstan.com:4431/push/incidencia";
updateIncidenciaPushURL:string="https://backbone.berekstan.com:4431/push/update";

//INCIDENCIAS BETWEEN DATES
incBetweenDatesURL:string="https://backbone.berekstan.com:4433/core/admin/get/incidenciasBD";


//RESERVATIONS
padelGetComunity:string="https://backbone.berekstan.com:4430/booking/padel/all/bycode";
padelCancelOne:string="https://backbone.berekstan.com:4430/booking/padel/disableReserva";
localGetComunity:string="https://backbone.berekstan.com:4439/booking/local/all/bycode";
localCancelOne:string="https://backbone.berekstan.com:4439/booking/local/disableReserva";

//RESERVATIONS PISCINE/SOLARIUM
poolGetComunity:string="https://backbone.berekstan.com:4490/booking/pool/all/bycode";
poolCancelOne:string="https://backbone.berekstan.com:4490/booking/pool/disableReserva";
solariumGetComunity:string="https://backbone.berekstan.com:4492/booking/solarium/all/bycode";
solariumCancelOne:string="https://backbone.berekstan.com:4492/booking/solarium/disableReserva";

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    ) {
    }
// ADMIN SUPER ALTA DE DATOS
fetchAllComunidades(): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlZeus}/get/comunidades`, {});
}
addAdminUser(data: AdminUserModel): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/admin`, {data});
}
addComunidad(data: ComunidadModel): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/comunity`, {data});
}
addPortal(data: PortalModel): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/portal`, {data});
}

addPhone(data: PhoneModel): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/phone`, {data});
}

addService(data: ServiceModel): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/service`, {data});
}

addExtras(data: ComunidadExtras): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/extras`, {data});
}

/// ************* /////
doFetchPhones(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlCore}/get/phones`, {code});
}
doFetchServices(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlCore}/get/services`, {code});
}
doFetchUsers(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlCore}/get/users`, {code});
}
doFetchReservasLocal(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlBookingLocal}/local/all/bycode`, {code});
}
doFetchReservasPadel(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlBookingPadel}/padel/all/bycode`, {code});
}
doFetchReservasPiscina(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlBookingPiscina}/pool/all/bycode`, {code});
}
doFetchReservasSolarium(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlBookingSolarium}/solarium/all/bycode`, {code});
}

doFetchIncidencias(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlCore}/admin/get/incidencias`, {code});
}
requestUpdateIncidenciaState(code: string): Observable<any> {
  return this.doAuthPostRequest(`${environment.apiUrlCore}/admin/get/incidencias`, {code});
}
doLogin(email: string, password: string): Observable<any> {
  return this.doPostRequest(`${environment.apiUrl}/admin/login`, {email, password});
}


requestGetNews(code:string){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/get/news`, {code});
}

requestAddNews(code_comunity:string,title:string, description:string, image){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/add/news`, {code_comunity, title, description, image});
}

requestDeleteNews(code:string,id:string){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/delete/news`, {code, id}); 
}

requestChangeIncidenciaState(code:string,id:string){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/update/incidenciaState`, {code, id});  
}

requestchangeIncidenciaOcultoState(code:string,id:string){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/update/incidenciaOcultoState`, {code, id});   
}
requestUpdateUserState(code:string,id_list:string,action:string){
  let state;
  switch (action) {
    case "bloquear":
      state="1";
      break;
   case "desbloquear":
      state="0";
      break;
  
   case "suprimir":
      state="2";
      break;
  }
  console.log('state rest : '+state);
  console.log('id list rest : '+id_list);
  return this.doAuthPostRequest(`${environment.apiUrlCore}/update/userState`, {code, state, id_list});   
}

requestSendNoticiaPush(code:string){
  return this.doAuthPostRequest(`${environment.apiUrlPush}/news`, {code});
}

requestSendIncidenciaPush(code:string){
  return this.doAuthPostRequest(`${environment.apiUrlPush}/incidencia`, {code});
}
addAdminIncidencia(code:string,category:string,type:string, description:string){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/admin/add/incidencia`, {code, category, type, description});  
}

getIncidenciasBetweenDates(code:string, start:string, end:string){   //BASIC AUTHENTICATION
  return this.doAuthPostRequest(`${environment.apiUrlCore}/admin/get/incidenciaBD`, {code, start, end});
}
getServices(code:string){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/get/services`, {code});
}
getPhones(code:string){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/get/phones`, {code});
}
updateService(service:ServiceModel){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/update/service`, {service});
}

deleteService(service:ServiceModel){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/delete/service`, {service});
}

updatePhone(phone:PhoneModel){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/update/phone`, {phone});
}
deletePhone(phone:PhoneModel){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/delete/phone`, {phone});
}
sendSolicitudSoporte(name: string, email: string, message: string){
  return this.doAuthPostRequest(`${environment.apiUrlCore}/solicitud/support`, {name, email, message});
}
sendDoc(title:string, admin_email:string, image:string,destinatario:string){
   //BASIC AUTHENTICATION
      let username: string = 'client';
      let password: string = '12345client67890client';
      let headers = new HttpHeaders();

//POST PARAMETERS 
     // let code = 'CVH2015';
      let urlSearchParams = new HttpParams();
      //urlSearchParams.append('code', code_comunity);
        urlSearchParams.append('admin_email', admin_email);
      urlSearchParams.append('title', title);
      urlSearchParams.append('destinatario', destinatario);
      urlSearchParams.append('image', image);
      urlSearchParams.append("Content-Type", "application/x-www-form-urlencoded");

    //  headers.append("Authorization", "Basic " + btoa(username + ":" + password)); 
      headers.append("Content-Type", "application/x-www-form-urlencoded");
  return this.doGetRequest(`${environment.apiUrlCore}/add/documento`, {headers, urlSearchParams});
  
}





    doAuthPostRequest(url: string, body: any): Observable<any> {
      const auth_token = this.sessionService.fetchAutToken();
      const HEADERS = new HttpHeaders(
        {
          'content-type': 'application/json; charset=utf-8',
          'x-access-token': auth_token
        }
        );
      const options = {
        headers: HEADERS
      };
      return this.http.post<any>(url, body, options ).pipe(
          map( response => {
            return response;
          }),
          catchError(err => {
            return throwError(err);
          }),
        );
  }
  
    doPostRequest(url: string, body: any): Observable<any> {
      return this.http.post<any>(url, body).pipe(
          map( response => {
            return response;
          }),
          catchError(err => {
            return throwError(err);
          }),
        );
  }
  doGetRequest(url: string, params: any): Observable<any> {
      return this.http.get<any>(url, params).pipe(
          map( response => {
            return response;
          }),
          catchError(err => {
            return throwError(err);
          }),
        );
  }
  
  doAuthGetRequest(url: string, params: any): Observable<any> {
    const auth_token = this.sessionService.fetchAutToken();
    const HEADERS = new HttpHeaders(
      {
        'Content-type': 'application/json; charset=utf-8',
        'x-access-token': auth_token
      }
      );
    const options = {
      headers: HEADERS
    };
    return this.http.get<any>(url, params).pipe(
        map( response => {
          return response;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }
}


function throwError(err: any): import("rxjs/Observable").ObservableInput<any> {
  throw new Error('Function not implemented.');
}

