

export class IncidenciaModel {
    code_comunity: string;
    image: string;
    category: string;
    type: string;
    username: string;
    adress: string;
    description: string;
    created_at: Date;
    formattedDate: string;
    create: string;
    state: any;
    oculto: any;
}
