import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../services/http/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatTableDataSource} from '@angular/material';
import swal from 'sweetalert2';
import * as mime from 'mime-types';
import * as moment from 'moment';
import { ComunityModel } from '../../models/comunity.model';
import { StorageService } from '../../services/storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { NoticiaModel } from '../../model/noticia.model';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {
  refreshIcon = 'assets/img/refresh.png';
  list:boolean=true;
  nueva:boolean=false;
  model:Model={
    title:"",
    description:"",
    file:null,
    ext:''
  };
  description:string;
  complexForm : FormGroup;
  addFileURL:string="https://backbone.berekstan.com:4438/upload";

 displayedColumns = ['position', 'fecha', 'titulo', 'description',  'actions'];
 
 selectedComunidad: ComunityModel;
noticias=[];
dataSource = new MatTableDataSource(this.noticias);
title_noticia:string="No hay noticias";
selectedTab :number= 0;
selectedNoticia: NoticiaModel;

  constructor(
    private http: HttpClient, 
    private httpService: HttpService, 
    private storageService: StorageService,
    private fb:FormBuilder) {
     this.complexForm = this.fb.group({
      // We can set default values by passing in the corresponding value or leave blank if we wish to not set the value. For our example, we’ll default the gender to female.
      'titulo' : [null, Validators.required],
      'description': [null, Validators.required]
    })

   }

  ngOnInit() {
      this.loadSelectedComunity();
      this.listenComunityChange();
  }
  exitNoticia(done) {
    this.selectedNoticia = null;
  }
  selectNoticia(incidencia) {
    this.selectedNoticia = incidencia;
  }
  refresh() {
    this.loadSelectedComunity();
  }
  loadSelectedComunity() {
    this.selectedComunidad = this.storageService.fetchSelectedComunity();
    console.log('SELECTED_COM -> ', this.selectedComunidad);
    if (this.selectedComunidad) {
      this.getNoticias();
    }
  }
  listenComunityChange() {
    this.storageService.onSelectedComunidadChange()
    .subscribe( com => {
      console.log('COMUNITY_CHANGE -> NOTICIAS -> ', com);
     if ( com ) {
       this.selectedComunidad = com;
       this.getNoticias();
     }
    });
  }
reload() {
 location.reload();
}
  getNoticias(){
   this.httpService.requestGetNews(this.selectedComunidad.code).subscribe((response)=>{
    console.log('GET_NEWS -> ', response);
     
     let result = response['result'];
     if(result){
        this.noticias=result;
        this.dataSource = new MatTableDataSource(result);    
        this.selectTab(0);
     }
    }, error => {
    }); 
  }

 submitForm(value: any):void{
    console.log('Reactive Form Data: ')
    console.log(value);
  }


  upload() {

    if(this.model.file!=null && this.model.file!=undefined){
      const formData: any = new FormData();
      var stamp = this.getTimeStamp();
      var filename = 'doc_'+stamp+'.'+this.model.ext;
      console.log('filename',filename);
      console.log('file',JSON.stringify(this.model.file));
      formData.append("uploads[]",this.model.file,filename);

    
    this.http.post(this.addFileURL, formData)
        .subscribe(response =>{
          let result = response['result'];
          if(result)
          this.addNoticia(this.model.title,this.model.description,filename);
          else
          this.uploadError();
        });
      
    }else{
      let filename = '';
      this.addNoticia(this.model.title,this.model.description,filename);
    } 
}

onFileChange(event) {
  let reader = new FileReader();
  if(event.target.files && event.target.files.length > 0) {
    let file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      
      var extension = this.getExtension(file.type);
      if(extension=='pdf' || extension=='png' || extension=='jpg'){
        this.model.ext = extension;
        this.model.file = file;
       }else{
        this.fileError();
      }  
    };
  }
}


getExtension(type){
  return mime.extension(type);
}
getTimeStamp(){
  return moment().format('YYYY_MM_DD_HH_mm_ss');  
}

  addNoticia(title:string, description:string, file){

     this.httpService.requestAddNews(this.selectedComunidad.code, title, description, file).subscribe((response)=>
    {
 
      this.httpService.requestSendNoticiaPush(this.selectedComunidad.code).subscribe((response)=>
      {
        this.success();
        this.model = {
          title:'',
          description:'',
          file:null,
          ext:''
        };

    });

    });
    this.getNoticias();  
  }

  setList(){
    this.getNoticias();
    this.list=true;
    this.nueva=false;
    
  }

  setNuevaNoticia(){
    this.list=false;
    this.nueva=true; 
  }

  onDeleteNoticia(noticia) {
    swal({
      title: 'Confirmación',
      text: "¿ Está seguro que quiere suprimir esta noticia ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {   
        this.httpService.requestDeleteNews(this.selectedComunidad.code, noticia.id).subscribe((response)=>
        {
          this.getNoticias();  
        });
      }
    });
    this.selectedNoticia = null;
  }
  deleteNoticia(index:number,id){
    swal({
      title: 'Confirmación',
      text: "¿ Está seguro que quiere suprimir esta noticia ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.noticias.splice(index,1);
        this.dataSource = new MatTableDataSource(this.noticias);   
        this.httpService.requestDeleteNews(this.selectedComunidad.code, id).subscribe((response)=>
        {
          this.getNoticias();  
        });
      }
    })
    this.selectedNoticia = null;
  }


 applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  selectTab(index: number){
    this.selectedTab = index;
    console.log('selectedTab tab: '+index);
  }

  info1(){
    if(localStorage.getItem('info1')){

    }else{
      swal(
        'Info !',
        'Ahora puedes adjuntar un archivo a tus noticias',
        'info'
      );
      localStorage.setItem('info1','true');
        
    }

  }

  success(){
    swal(
      'Perfecto !',
      'Noticia enviada correctamente',
      'success'
    );
  }
  fileError(){
    swal(
      'Lo siento !',
      'Solo se pueden seleccionar imagen y pdf',
      'error'
    );
  }

  uploadError(){
    swal(
      'Lo siento !',
      'Un error se ha producido subiendo enviando la noticia, por favor intentar más tarde',
      'error'
    );
  }

}

interface Model{
  title:string,
  description:string,
  file:any,
  ext:string
}
