import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../services/http/http.service";
import { LocalModel } from "../../models/local.model";
import { PadelModel } from "../../models/padel.model";
import { MatTableDataSource } from "@angular/material";
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { ExporterService } from "../../services/exporter/exporter.service";
import { AlertService } from "../../services/alert/alert.service";
import { StorageService } from "../../services/storage/storage.service";
import { PiscinaModel } from "../../models/piscina.mode";
import { SolariumModel } from "../../models/solarium.model";
import { ComunidadModel } from "../../model/comunidad.model";
import { UserModel } from "../../model/vecino.model";
import { ModalService } from "../../services/modal/modal.service";


@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"]
})
export class BookingComponent implements OnInit {
  refreshIcon = 'assets/img/refresh.png';
  disable = false;
  enabled = {
    local: false,
    padel: false,
    pool: false,
    solarium: false
  };
  selectedComunidad: ComunidadModel;
  users: UserModel[];
  localBooking: LocalModel[];
  padelBooking: PadelModel[];
  piscinaBooking: PiscinaModel[];
  solariumBooking: SolariumModel[];
  imageDisable = "assets/img/disable.png";
  padelDataSource = new MatTableDataSource([]);
  localDataSource = new MatTableDataSource([]);
  piscinaDataSource = new MatTableDataSource([]);
  solariumDataSource = new MatTableDataSource([]);
   messageDisable = " ¿ Aún no tienes activado el sistema de reserva de local y padel en tu comunidad ? <br> ¡ Contactanos para saber màs ! ";
  messageEmptyPadel = "No hay ninguna reservas de padel en esta comunidad";
  messageEmptyLocal = "No hay ninguna reservas de local en esta comunidad";
  messageEmptyPiscina = "No hay ninguna reservas de piscina en esta comunidad";
  messageEmptySolarium = "No hay ninguna reservas de solarium en esta comunidad";
  padelcolumns= ['position','day', 'name', 'adress','hours','active', 'actions'];
  localcolumns= ['position', 'day', 'name', 'adress', 'hours', 'countHours', 'active', 'actions'];
  piscinacolumns= ['position', 'day', 'name', 'adress', 'hours','persons','active', 'actions'];
  solariumcolumns= ['position', 'day', 'name', 'adress', 'hours','persons','active', 'actions'];
  filtroFechaItems = [
    {label:'Todos',value:'todo'},
    {label:'Este mes',value:'this'},
    {label:'El mes pasado',value:'prev'},
    {label:'El mes siguiente',value:'next'},
    {label:'Buscar con intervales',value:'interval'},
  ];
  selectedFechaOption = 'todo';
  date = new Date();
  fechaIn:string;
  fechaFin:string;
  localCount = 0;
  localMoneySum = 0;
  padelCount = 0;
  piscinaCount = 0;
  solariumCount = 0;
  setDateinput = {
    in: {
      bool: false,
      value: new Date()
    },
    fin:  {
      bool: false,
      value: new Date()
    }
  };
  localDataCached:LocalModel[]=[];
  USER_ACCOUNT_TYPE = 'NORMAL';
  selectedItem: any;
  constructor(private datePipe:DatePipe,public httpService: HttpService,public alertService:AlertService,
    public exporterService:ExporterService, public storageService:StorageService, private modalService: ModalService) {}

  ngOnInit() {
    this.init();
    this.getForfait();
  }
  loadSelectedComunity() {
    this.selectedComunidad = this.storageService.fetchSelectedComunity();
    if (this.selectedComunidad) {
      this.getForfait();
      this.reloadData();
    }
  }
  init() {
    this.loadSelectedComunity();
    this.listenComunityChange();
  }
  exitDetail(done) {
    this.selectedItem = null;
  }
  selectItem(item) {
    console.log('RESERVA_ITEM -> ', item);
    this.selectedItem = item;
  }
  listenComunityChange() {
    this.storageService.onSelectedComunidadChange()
    .subscribe( com => {
     if ( com ) {
       this.selectedComunidad = com;
       this.reloadData();
     }
    });
  }
  getForfait() {
    this.USER_ACCOUNT_TYPE = this.storageService.getForfait();
  }
  reloadData() {
    this.getUsers();
  }

  refresh() {
    this.loadSelectedComunity();
  }
  chargeLocalBookingToTable(data) {
    this.localBooking = this.mapLocalBooking(data);
    this.localCount = this.localBooking.length;
    this.localDataSource  = new MatTableDataSource(this.localBooking);
    this.calculateLocalMoneySum(this.localBooking);
    this.enabled.local = true;
}
chargePadelBookingToTable(data) {
this.padelBooking = this.mapPadelBooking(data);
this.padelCount = this.padelBooking.length;
this.padelDataSource  = new MatTableDataSource(this.padelBooking);
this.enabled.padel = true;
}
chargePiscinaBookingToTable(data) {
  this.piscinaBooking = this.mapPiscinaBooking(data);
      this.piscinaCount = this.piscinaBooking.length;
      this.piscinaDataSource  = new MatTableDataSource(this.piscinaBooking);
      this.enabled.pool = true;
}
chargeSolariumBookingToTable(data) {
  this.solariumBooking = this.mapSOlariumBooking(data);
  this.solariumCount = this.solariumBooking.length;
  this.solariumDataSource  = new MatTableDataSource(this.solariumBooking);
  this.enabled.solarium = true;
}
  getLocalBooking() {
    this.reloadLocalReservas();
/*     const data = this.storageService.fetchLocalBooking();
    if(data && data.length > 0){
      this.chargeLocalBookingToTable(data);
    }else{
      this.reloadLocalReservas();
    } */
  }

  getPadelBooking() {
    this.reloadPadelReservas();
/*     const data = this.storageService.fetchPadelBooking();
    if(data && data.length > 0){
     this.chargePadelBookingToTable(data);
    } else {
      this.reloadPadelReservas();
    } */
  }

  getPiscinaBooking() {
    this.reloadPiscinaReservas();
/*     const data = this.storageService.fetchPiscinaBooking();
    if(data && data.length > 0){
      this.chargePiscinaBookingToTable(data);
    } else {
    this.reloadPiscinaReservas();
    } */
  }

  getSolariumBooking() {
    this.reloadSolariumReservas();
/*     const data = this.storageService.fetchSolariumBooking();
    if(data && data.length > 0){
     this.chargeSolariumBookingToTable(data);
    } else {
     this.reloadSolariumReservas();
    } */
  }

  reloadLocalReservas() {
    this.httpService.doFetchReservasLocal(this.selectedComunidad.code)
    .subscribe( data => {
      // this.storageService.saveLocalBooking(data);
      if(data && data.length>0){
        this.localBooking = data;
        this.chargeLocalBookingToTable(data);
      } else {
        this.enabled.local = false;
        this.localBooking = [];
      }
    });
  }
  reloadPadelReservas() {
    this.httpService.doFetchReservasPadel(this.selectedComunidad.code)
    .subscribe( data => {
      // this.storageService.savePadelBooking(data);
      if(data && data.length>0){
        this.padelBooking = data;
        this.chargePadelBookingToTable(data);
      } else {
        this.enabled.padel = false;
        this.padelBooking = [];
      }
    
    });
  }
  reloadPiscinaReservas() {

    this.httpService.doFetchReservasPiscina(this.selectedComunidad.code)
    .subscribe( data => {
      // this.storageService.savePiscinaBooking(data);
      if(data && data.length>0){
        this.piscinaBooking = data;
        this.chargePiscinaBookingToTable(data);
      } else {
        this.enabled.pool = false;
        this.piscinaBooking = [];
      }
    
    });
  }
  reloadSolariumReservas() {
    this.httpService.doFetchReservasSolarium(this.selectedComunidad.code)
    .subscribe( data => {
      // this.storageService.saveSolariumBooking(data);
      if(data && data.length>0){
        this.solariumBooking = data;
        this.chargeSolariumBookingToTable(data);
      } else {
        this.enabled.solarium = false;
        this.solariumBooking = [];
      }
    
    });
  }

  reloadAllReservas() {
    this.reloadLocalReservas();
    this.reloadPadelReservas();
    this.reloadPiscinaReservas();
    this.reloadSolariumReservas();
  }

  getBookings() {
    this.getLocalBooking();
    this.getPadelBooking();
    this.getPiscinaBooking();
    this.getSolariumBooking();
  }
  getUsers() {
    this.loadUsers();
  }
  loadUsers() {
    this.httpService.doFetchUsers(this.selectedComunidad.code)
    .subscribe( data => {
      this.storageService.saveUsers(data.result);
      if(data && data.result.length>0){
        this.users = data.result;
        this.getBookings();
      }
    });
  }
  applyLocalFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.localDataSource.filter = filterValue;
  }

  applyPadelFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.padelDataSource.filter = filterValue;
  }

  applyPiscinaFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.piscinaDataSource.filter = filterValue;
  }

  applySolariumFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.solariumDataSource.filter = filterValue;
  }
    mapLocalBooking(items){
      let result: LocalModel[]=[];
      if ( items && items.length > 0 && this.users &&  this.users.length > 0) {
        for (const book of items ) {
          for (const user of this.users) {
            if(user.code_house === book.userId ) {
              const size = book.hours.length;
              //let price = `${size} €`;
              let price = `${size}`;
              let countHours = `${size}`;
              let hours = `${book.hours[0]}h - ${Number(book.hours[size-1])+1}h`;
              let fecha = new Date(book.day);
              let now = new Date();
              let state = now.getTime() > fecha.getTime() ? 'pasado' : (book.active ? 'true' : 'false');
              let item: LocalModel = {
                countHours,
                id: book._id,
                code: book.code,
                name: user.name,
                adress: user.adress,
                price: price,
                day: book.day,
                hours: hours,
                active: state
              };
              let exist = result.findIndex( el => el.id === item.id);
              if (exist===-1) {
              result.push(item);
              }
            }
          }
        }
      }
      return result;
    }
    mapPadelBooking(items){
      let result: PadelModel[]=[];
      items.forEach( book => {
        this.users.forEach( user => {
          if(user.code_house === book.userId ) {
            let hours = book.hour.split(',');
            const size = hours.length;
            let strhours = `${hours[0]}h - ${Number(hours[size-1]) +1}h`;
            let fecha = new Date(book.day);
            let now = new Date();
            let state;
            if (book.active) {
             state = now.getTime() > fecha.getTime() ? 'pasado' : (book.active ? 'true' : 'false');
            }
            let item: PadelModel = {
              id: book._id,
              code: book.code,
              name: user.name,
              adress: user.adress,
              day: book.day,
              hours: strhours,
              active: state
            };
            let exist = result.findIndex( el => el.id === item.id);
            if (exist===-1) {
            result.push(item);
            }
          }
        });
      });
      return result;
    }
    mapPiscinaBooking(items){
      let result: PiscinaModel[]=[];
      items.forEach( book => {
        this.users.forEach( user => { 
          if(user.code_house === book.userId ) {
            let fecha = new Date(book.day);
            let now = new Date();
            let state;
            if (book.active) {
             state = now.getTime() > fecha.getTime() ? 'pasado' : 'activo';
            } else {
              state = 'cancelado';
            }
            let item: PiscinaModel = {
              id: book._id,
              code: book.code,
              username: book.username,
              useraddress: book.useraddress,
              persons: book.persons,
              day: book.day,
              userId: book.userId,
              hour:  `${book.hour}h`,
              active: state
            };
            let exist = result.findIndex( el => el.id === item.id);
            if (exist===-1) {
            result.push(item);
            }
          }
        });
      });
      return result;
    }

    mapSOlariumBooking(items){
      let result: SolariumModel[]=[];
      items.forEach( book => {
        this.users.forEach( user => {
          if(user.code_house === book.userId ) {
            let fecha = new Date(book.day);
            let now = new Date();
            let state;
            if (book.active) {
             state = now.getTime() > fecha.getTime() ? 'pasado' : 'activo';
            } else {
              state = 'cancelado';
            }
            let item: SolariumModel = {
              id: book._id,
              code: book.code,
              username: book.username,
              useraddress: book.useraddress,
              persons: book.persons,
              day: book.day,
              userId: book.userId,
              hour:  `${book.hour}h`,
              active: state
            };
            let exist = result.findIndex( el => el.id === item.id);
            if (exist===-1) {
            result.push(item);
            }
          }
        });
      });
      return result;
    }
    onDateFilterChange(event){
      let month;
      let result;
      let values;
      switch(event.value){
        case 'this':
         month = new Date().getMonth();
         result = this.localBooking;
         values = [];
        result.forEach( (item) => {
          let itemmonth = new Date(item.day).getMonth();
          if(itemmonth===month){
            values.push(item);
          }
        });
        this.localDataSource  = new MatTableDataSource(values);
        this.clearDatePickerInput();
        break;
        case 'prev':
         month = new Date().getMonth() - 1;
         result = this.localBooking;
         values = [];
        result.forEach( (item) => {
          let itemmonth = new Date(item.day).getMonth();
          if(itemmonth===month){
            values.push(item);
          }
        });
        this.localDataSource  = new MatTableDataSource(values);
        this.clearDatePickerInput();
        break;
        case 'next':
        month = new Date().getMonth() + 1;
         result = this.localBooking;
         values = [];
        result.forEach( (item,index,array) => {
          let itemmonth = new Date(item.day).getMonth();
          if(itemmonth===month){
            values.push(item);
          }
        });
        this.localDataSource  = new MatTableDataSource(values);
        this.clearDatePickerInput();
        break;
        case 'interval':
        values = [];
        this.localDataSource  = new MatTableDataSource(values);
        break;
        case 'todo':
        values = this.localBooking;
        this.localDataSource  = new MatTableDataSource(this.localBooking);
        this.clearDatePickerInput();
        break;
      }
      this.localCount = values.length;
      this.calculateLocalMoneySum(values);
    }
    applyLocalDateFilter(){

    }
    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
      let value = event.value;
      let result = [];
      if(type==='in') {
        this.setDateinput.in.bool = true;
        this.setDateinput.in.value = value;
      }else if(type==='fin'){
        this.setDateinput.fin.bool = true;
        this.setDateinput.fin.value = value;
      }
      if(this.setDateinput.in.bool && this.setDateinput.fin.bool){
        this.localBooking.forEach( item => {
          let fechatime = new Date(item.day).getTime();
          let intime = this.setDateinput.in.value.getTime();
          let fintime = this.setDateinput.fin.value.getTime();
          if( (intime < fechatime) && ( fechatime < fintime) ){
            result.push(item);
          }
        });
        this.localCount = result.length;
        this.calculateLocalMoneySum(result);
        this.localDataSource  = new MatTableDataSource(result);
      }
    }
    clearDatePickerInput(){
      this.setDateinput = {
        in: {
          bool: false,
          value: new Date()
        },
        fin:  {
          bool: false,
          value: new Date()
        }
      };
    }

    formatDate(date) {
      return  this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
     }

  Export(event){
   if(this.USER_ACCOUNT_TYPE === 'PRO'){
    const type = event.value;
    const filename = 'reserva_local';
    const clonedList = this.storageService.cloneObject(this.localDataCached);
    const data:LocalModel[] = this.mappLocalDataToExcelFormat(clonedList);
    console.log('export type',type);
    switch(type){
      case "excel":
      this.exporterService.exportAsExcelFile(data,filename);
      break;
      case "csv":
      this.exporterService.exportAsCSVFile(data,filename);
      break;
    }
   }else{
    let feature = 'exportar los datos de las reservas al formato excel';
    this.alertService.alertProFeature(feature);
   }
  }
  mappLocalDataToExcelFormat(items:LocalModel[]){
    items.forEach( item => {
      delete item.id;
      delete item.active;
      item.price = `${item.price} €`;
    });
    return items;
  }
  calculateLocalMoneySum(items:LocalModel[]) {
    this.localDataCached = items;
    this.localMoneySum = 0;
    items.forEach( item => {
      this.localMoneySum += Number(item.price);
    })
  }


}
