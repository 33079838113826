import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ComunityModel } from '../../models/comunity.model';
import { StorageService } from '../../services/storage/storage.service';
import { AdminInfosModel } from '../../model/admin-infos.model';
import { HttpService } from '../../services/http/http.service';
import { SessionService } from '../../services/session/session.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  logged:boolean=false;

 model: any = {};
    loading = false;
    returnUrl: string;
    admin_info:ComunityModel;
    wrong_data:boolean =false;
    listComunity:ComunityModel[];

  constructor(private route: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService,
        private httpService: HttpService,
        private storageService: StorageService,
        ) { 

        }

  ngOnInit() {
    this.hasSession();
  }


   login() {
    this.httpService.doLogin(this.model.username, this.model.password)
    .subscribe( (response) => {
      if (response) {
        const auth_token = response.result[0]['auth_token'];
        const adminInfos: AdminInfosModel = response.result[0];
        this.sessionService.saveAuthToken(auth_token);
        this.storageService.saveAdminInfos(adminInfos);
        this.storageService.saveComunidades( response.result );
        this.sessionService.login();
      }
    });
    }
    hasSession() {
      if ( this.sessionService.isLoggedIn()) {
        this.router.navigate(['home']);
      }
    }
}
