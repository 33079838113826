import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NoticiaModel } from '../../../model/noticia.model';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss']
})
export class NoticiaComponent implements OnInit {
  @Input() data: NoticiaModel;
  @Output() done = new EventEmitter<Boolean>(false);
  @Output() delete = new EventEmitter<any>(null);
  constructor() { }

  ngOnInit() {
  }

  deleteNoticia() {
    this.delete.emit(this.data);
    this.close();
  }
  close() {
    this.done.emit(true);
  }
}
