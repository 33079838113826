import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IncidenciaModel } from '../../../model/incidencia.model';

@Component({
  selector: 'app-incidencia',
  templateUrl: './incidencia.component.html',
  styleUrls: ['./incidencia.component.scss']
})
export class IncidenciaComponent implements OnInit {
  @Input() data: IncidenciaModel;
  @Output() done = new EventEmitter<Boolean>(false);
  constructor() { }

  ngOnInit() {
  }

  save() {
    this.done.emit(true);
  }
  close() {
    this.done.emit(true);
  }
}
